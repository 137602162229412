import { Injectable } from "@angular/core";
import { AssetType } from "../../../shared/types/asset.type";
import { ICommonPrefixes } from "../../../shared/types/common-prefixes.type";
import { ObjectType } from "../../../shared/types/object-type";
import { S3Object } from "../../../shared/types/s3-object.type";
import { Mapper } from "./mapper";

@Injectable({
  providedIn: 'root'
})
export class FolderMapper implements Mapper<S3Object, AssetType[]> {

  private root: string;

  setRoot(root: string) {
    this.root = root;
  }

  toDomain(input: S3Object): AssetType[] {
    return input.CommonPrefixes.map((object: ICommonPrefixes) => {
      return {
        name: this.formatFolderName(object.Prefix, input.Prefix),
        type: ObjectType.Folder,
        path: object.Prefix,
        index: this.makeIndexByPrefix(object.Prefix)
      }
    })
  }

  private makeIndexByPrefix(path: string): number {
    const pathIndex = path.replace(this.root, '').split('/');

    pathIndex.pop();

    return pathIndex.length;
  }

  private nameWithoutPrefix (name: string, prefix: string): string {
    return name.replace(prefix, '');
  }

  private formatFolderName (name: string, prefix: string): string {
    return this.nameWithoutPrefix(name, prefix).replace('/', '');
  }
}
