import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedAsset } from 'src/app/shared/types/selected-asset';

@Injectable({ providedIn: 'root' })
export class AssetSelectorService {
  private _selectedAssets = new BehaviorSubject<SelectedAsset[]>([]);
  readonly selectedAssets$ = this._selectedAssets.asObservable();

  downloadLimit = 50;

  get assets(): SelectedAsset[] {
    return this._selectedAssets.value;
  }

  get paths(): string[] {
    return this.assets.map(({ path }) => path);
  }

  get isSelectionFull(): boolean {
    return this.assets.length >= this.downloadLimit;
  }

  endsWithFolder(path: string, folder: string): boolean {
    const paths = path.split('/');
    paths.pop(); // remove 'asset.png'
    return paths.join('/').endsWith(folder);
  }

  getFolder(path: string): string {
    const paths = path.split('/');
    return paths[paths.length - 2];
  }

  checkAll(assets: SelectedAsset[]): void {
    if (assets.length <= this.downloadLimit) {
      this._selectedAssets.next(assets);
    } else {
      this._selectedAssets.next(assets.slice(0, this.downloadLimit));
    }
  }

  uncheckAll(folder?: string): void {
    if (!folder) {
      this._selectedAssets.next([]);
      return;
    }

    const remainingAssets = this.assets.filter(
      ({ path }) => !this.endsWithFolder(path, folder)
    );

    this._selectedAssets.next(remainingAssets);
  }

  addAsset(asset: SelectedAsset): void {
    if (this.isSelected(asset)) return;

    const folder = this.getFolder(asset.path);

    const remainingAssets = [...this.assets, asset].filter(({ path }) =>
      this.endsWithFolder(path, folder)
    );

    this._selectedAssets.next(remainingAssets);
  }

  removeAsset(asset: SelectedAsset): void {
    if (!this.isSelected(asset)) return;

    let remainingAssets = this.assets.filter(({ path }) => path !== asset.path);
    this._selectedAssets.next(remainingAssets);
  }

  isSelected(asset: SelectedAsset): boolean {
    return this.paths.includes(asset.path);
  }
}
