import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetType } from '../../../shared/types/asset.type';

export type ChangeFolderType = {
  assetType: AssetType;
  index: number;
}

@Component({
  selector: 'app-asset-folder',
  templateUrl: './asset-folder.component.html',
  styleUrls: ['./asset-folder.component.scss'],
})
export class AssetFolderComponent {
  @Input() folder: AssetType;
  @Input() index: number;
  @Input() selectedIndex: number;
  @Output() onChangeFolder: EventEmitter<ChangeFolderType> = new EventEmitter();

  get ariaLabel() {
    return !this.folder?.name?.trim().length
      ? 'Acessar pasta'
      : `Acessar pasta ${this.folder.name}`;
  }

  get link() {
    const { path } = this.folder;
    return path.startsWith('ftp-agencias')
      ? path.split('/').slice(1).join('/') // remove ftp-agencias
      : path.split('/').slice(4).join('/'); // remove storage/assetscloud/dam/storage
  }

  get loading() {
    return this.index === this.selectedIndex;
  }

  changeFolder() {
    if (this.loading) {
      return false;
    }

    this.onChangeFolder.emit({
      assetType: this.folder,
      index: this.index
    });
  }
}
