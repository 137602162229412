import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VoxelModule } from '../../shared/voxel.module';
import { AssetCheckboxComponent } from './asset-checkbox/asset-checkbox.component';
import { AssetFolderComponent } from './asset-folder/asset-folder.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { AssetPreviewComponent } from './asset-preview/asset-preview.component';
import { DownloadButtonComponent } from '../../files/download-button/download-button.component';
import { FilterAssetsPipe } from '../../shared/filter-assets.pipe';
import { TruncateTextPipe } from '../../shared/truncate-text.pipe';
import { AssetListActionsComponent } from './asset-list-actions/asset-list-actions.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    VoxelModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AssetListComponent,
    AssetCheckboxComponent,
    AssetPreviewComponent,
    AssetFolderComponent,
    DownloadButtonComponent,
    FilterAssetsPipe,
    AssetListActionsComponent,
    TruncateTextPipe,
  ],
  exports: [
    AssetListComponent,
    AssetCheckboxComponent,
    AssetPreviewComponent,
    AssetFolderComponent,
    AssetListActionsComponent,
    TruncateTextPipe,
    FilterAssetsPipe,
  ],
})
export class AssetSelectorModule {}
