import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom, map } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HTTPCSService {
  constructor(private http: HttpClient) {}

  buckets = new BehaviorSubject<any>([]);

  getAll(): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${environment.csApiUrl}`, {
      headers: {
        'api_key': environment.csApiKey,
        'access_token': environment.csAccessToken
      }
    })
    .pipe(
      map(response => {
        const buckets = response.entry.buckets //update buckets
        this.setBuckets(buckets)
        return buckets
      }),
    ))
  }

  setBuckets(buckets:any) {
    this.buckets.next(buckets)
  }

}
