/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const baseURL = environment.cfPreview;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters.
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * 
 * ### auth
 * - clientId: This is the ONLY mandatory field that you need to supply.
 * - authority: Defaults to "https://login.microsoftonline.com/common"
 * - redirectUri: Points to window.location.origin. You must register this URI on Azure portal/App Registration.
 * - postLogoutRedirectUri: Indicates the page to navigate after logout.
 * - navigateToLoginRequestUrl: If "true", will navigate back to the original request location before processing the auth code response.
 * 
 * ### cache
 * - cacheLocation: Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
 * - storeAuthStateInCookie: Set this to "true" if you are having issues on IE11 or Edge
 * 
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientID,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: `${baseURL}login`,
    navigateToLoginRequestUrl: true,
    authority: 'https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
};
