import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AssetSelectorService } from '../../components/asset-selector/asset-selector.service';
import { SelectedAsset } from '../../shared/types/selected-asset';
import { HTTPAssetsService } from '../bucket/services/http-assets.service';

export type DownloadObject = {
  previewUrl: string;
  name: string;
};

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadButtonComponent {
  loading = false;
  showDialog = false;
  interval: any;

  constructor(
    private selectorService: AssetSelectorService,
    private httpAssetsService: HTTPAssetsService
  ) {}

  get hasAssets(): boolean {
    return this.selectorService.assets.length > 0;
  }

  get ariaLabel() {
    if (this.selectorService.assets.length === 1) {
      const [{ name }] = this.selectorService.assets;
      return `baixar arquivo ${name}`;
    }
    return 'baixar arquivos';
  }

  toggleDialog(show: boolean): void {
    this.showDialog = show;
  }

  getAssetsToDownload(): DownloadObject[] {
    return this.selectorService.assets.map(this.toDownloadObject);
  }

  toDownloadObject({ name, previewUrl }: SelectedAsset): DownloadObject {
    return {
      previewUrl,
      name,
    } as DownloadObject;
  }

  createAnchor({ name, previewUrl }: DownloadObject): HTMLAnchorElement {
    const anchor = document.createElement('a');
    anchor.setAttribute('download', name);
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('href', previewUrl);
    return anchor;
  }

  cancel() {
    clearInterval(this.interval);
    this.selectorService.uncheckAll();
    this.loading = false;
    this.toggleDialog(false);
  }

  download(urls: DownloadObject[]) {
    return () => {
      let url: DownloadObject | undefined = urls.pop();

      if (url) {
        this.httpAssetsService
          .download(url.previewUrl)
          .subscribe((data: HttpResponse<Blob>) => {
            const urlBlobFile = URL.createObjectURL(data.body as Blob);
            const newUrlToDownload: DownloadObject = {
              name: url?.name ?? '',
              previewUrl: urlBlobFile,
            };
            const tempLink = this.createAnchor(newUrlToDownload);
            document.body.appendChild(tempLink);
            tempLink.click();
            tempLink.remove();
            URL.revokeObjectURL(urlBlobFile);
          });
      }

      if (urls.length === 0 && this.interval !== undefined) {
        this.cancel();
      }
    };
  }

  downloadAssets(): void {
    const urls = this.getAssetsToDownload();
    this.loading = true;
    this.interval = setInterval(this.download(urls), 500);
  }
}
