import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import {
  MsalGuardConfiguration,
  MsalGuard,
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MsalService,
  MsalRedirectComponent,
  MsalModule,
} from '@azure/msal-angular';

import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetSelectorModule } from './components/asset-selector/asset-selector.module';
import { LoginComponent } from './login/login.component';
import { VoxelModule } from './shared/voxel.module';
import { msalConfig } from './auth/auth-config';
import { HTTPCSService } from './files/bucket/services/http-cs.service';
import { Bucket } from './shared/types/bucket.type';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

export function AppInitializerFactory(
  router: Router,
  csService: HTTPCSService
): () => Promise<void> {
  return async () => {
    const data = await csService.getAll();
    const dynamicRoutes = data.map((item: any) => {
      return {
        path: item.path,
        loadChildren: () =>
          import('./files/files.module').then((m) => m.FilesModule),
        data: {
          bucket: item.bucket,
          root: item.root,
          cf: item.cf,
          title: item.title,
          path: item.path,
        } as Bucket,
      };
    });
    const finalRoutes = dynamicRoutes.concat(router.config);
    router.resetConfig(finalRoutes);
  };
}

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    VoxelModule,
    AssetSelectorModule,
    MsalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      multi: true,
      deps: [Router, HTTPCSService],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
