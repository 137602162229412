<a [attr.aria-label]="ariaLabel" [routerLink]="link" (click)="changeFolder()">
  <div class="voxel-card">
    <div>
      <p>
        {{ folder.name | truncateText }}
      </p>
      <p aria-hidden="true">
        <ng-container *ngIf="!loading">
          Acessar
          <voxel-icon aria-hidden="true" style="color: var(--principal)">seta_direita</voxel-icon>
        </ng-container>

        <ng-container *ngIf="loading">
          <voxel-loading-circle class="voxel-loading-circle" size="medium"></voxel-loading-circle>
        </ng-container>
      </p>
    </div>
    <!-- tooltip -->
    <span>
      {{ folder.name }}
    </span>
  </div>
</a>
