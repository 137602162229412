import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetType } from '../../../shared/types/asset.type';
import { SelectedAsset } from '../../../shared/types/selected-asset';

@Component({
  selector: 'app-asset-checkbox',
  templateUrl: './asset-checkbox.component.html',
  styleUrls: ['./asset-checkbox.component.scss'],
})
export class AssetCheckboxComponent {
  @Input() asset: AssetType;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<SelectedAsset> = new EventEmitter();

  onChecked(event?: any) {
    if (!event) {
      this.checked = !this.checked;
    } else {
      this.checked = event.target.checked;
    }

    this.onChange.emit({
      path: this.asset.path,
      checked: this.checked,
      name: this.asset.name,
      size: this.asset.size,
      previewUrl: this.asset.previewUrl,
      isPreview: this.asset.isPreview,
      lastDateModified: this.asset.lastModified,
    });
  }
}
