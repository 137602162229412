import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, map } from "rxjs";
import { ObjectType } from "../../../shared/types/object-type";
import { environment } from "../../../../environments/environment";
import { AssetType } from "../../../shared/types/asset.type";
import { S3Object } from "../../../shared/types/s3-object.type";
import { BucketStore } from "../list.service";
import { AssetsMapper } from "../mapper/assets-mapper";
import { AssetsService } from "./assets.service";

@Injectable({
  providedIn: 'root'
})
export class HTTPAssetsService implements AssetsService {

  private bucket: string = '';
  private cf: string = '';
  private root: string = '';

  constructor(private http: HttpClient, private assetsMapper: AssetsMapper) {}

  setBucket(bucket: string) {
    this.bucket = bucket;
  }

  setCf(cf: string) {
    this.cf = cf;
    this.assetsMapper.setCf(this.cf);
  }

  setRoot(root: string) {
    this.root = root;
    this.assetsMapper.setRoot(this.root);
  }

  async pagination(token: string, path: string): Promise<BucketStore> {
    return await this.getAll(path, token);
  }

  getAll(prefix?: string, token?: string): Promise<BucketStore> {

    const urlSearch = new URLSearchParams();

    urlSearch.append('mode', 'list');

    urlSearch.append('bucket', this.bucket);

    if (prefix) {
      urlSearch.append('prefix', prefix);
    }

    if (token) {
      urlSearch.append('token', token);
    }

    return firstValueFrom(this.http.get<S3Object>(`${environment.apiUrl}/assetviewer?${urlSearch.toString()}`, {
      headers: {
        'x-origin-cf-apigw': environment.originCfApigw,
        'x-itau-correlationID': environment.correlationID
      }
    }).pipe(map(response => this.assetsMapper.toDomain(response))))
  }

  async getRoot(prefix: string): Promise<AssetType[]> {
    const response = await this.getAll(prefix);

    return response.objects.filter((object: AssetType) => object.type === ObjectType.Folder);
  }

  download(path: string) {
    return this.http.get(path, { responseType: 'blob', observe: 'response' });
  }

}
