import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(text: string, max = 30): string {
    if (!text.trim() || text.trim().length <= max) return text;

    return `${text.slice(0, max)}...`;
  }
}
