import { Pipe, PipeTransform } from '@angular/core';
import { AssetType } from './types/asset.type';

@Pipe({
  name: 'filterAssets',
})
export class FilterAssetsPipe implements PipeTransform {
  transform(items: AssetType[], search: string): AssetType[] {
    if (!items.length) return [];

    if (!search.trim().length) return items;

    return items.filter(({ name }) =>
      name.toLowerCase().includes(search.trim().toLowerCase())
    );
  }
}
