import { Component, Input } from '@angular/core';
import { SelectedAsset } from 'src/app/shared/types/selected-asset';

export const assetAltText = 'imagem do asset selecionado';
export const genericAltText = 'imagem de vários arquivos agrupados';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss'],
})
export class AssetPreviewComponent {
  @Input() items: SelectedAsset[];
  genericImage = '../../../../assets/preview.png';

  get ariaLabel() {
    return this.count > 1
      ? 'Pré-visualização dos arquivos selecionados'
      : 'Pré-visualização do arquivo selecionado';
  }

  get count() {
    return this.items.length;
  }

  get previewImage() {
    const asset = this.items?.length ? this.items[0] : null;
    return asset?.isPreview ? asset.previewUrl : '';
  }

  get altText() {
    const asset = this.items?.length ? this.items[0] : null;
    return asset?.isPreview ? assetAltText : genericAltText;
  }
}
