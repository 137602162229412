import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AssetType } from "../../shared/types/asset.type";
import { HTTPAssetsService } from "./services/http-assets.service";

export type BucketStore = {
  objects: AssetType[];
  pagination: boolean;
  token: string;
  indexPath: number;
  path: string;
};

const INITIAL_STATE: BucketStore[] = []

@Injectable({
  providedIn: 'root'
})
export class ListObjectsInBucketService {

  private readonly _bucketState = new BehaviorSubject<BucketStore[]>(INITIAL_STATE);

  readonly bucketState$ = this._bucketState.asObservable();

  private lastIndex: number = -1;

  private bucket: string = '';
  private cf: string = '';
  private root: string = '';

  constructor(
    private assetsService: HTTPAssetsService
  ) {}

  setBucket(bucket: string) {
    this.bucket = bucket;
    this.assetsService.setBucket(this.bucket);
  }

  setCf(cf: string) {
    this.cf = cf;
    this.assetsService.setCf(this.cf);
  }

  setRoot(root: string) {
    this.root = root;
    this.assetsService.setRoot(this.root);
  }

  get bucketState() {
    return this._bucketState.getValue();
  }

  set bucketState(bucketState) {
    this._bucketState.next(bucketState);
  }

  private setObjects(_objects: BucketStore, assetType: AssetType) {

    this.lastIndex = assetType.index;

    let bucketStore = this.bucketState.find((bucketStore: BucketStore) => bucketStore.indexPath === _objects.indexPath);

    if (!bucketStore) {
      this.bucketState = [_objects];
      return;
    }

    if (this.lastIndex >= assetType.index) {
      this.bucketState = this.bucketState.slice(0, assetType.index - 1);
      this.bucketState.push(_objects)
    }
  }

  search(assetType: AssetType) {
    return new Promise(async (resolve, reject) => {
      try {
        const objects = await this.getAllObjects(assetType.path);
        this.setObjects(objects, assetType);
        resolve(true);
      } catch (err) {
        reject(err);
      }
    })
  }

  getAllObjects(prefix?: string, token?: string): Promise<BucketStore> {
    return this.assetsService.getAll(prefix, token);
  }

  async getRootObject(prefix: string): Promise<AssetType[]> {
    return this.assetsService.getRoot(prefix);
  }

  async pagination(indexPath: number, token: string, path: string) {
    const response = await this.assetsService.pagination(token, path);

    let bucketStore = this.bucketState.find((bucketStore: BucketStore) => bucketStore.indexPath === indexPath);

    if (bucketStore) {
      bucketStore.pagination = response.pagination;
      bucketStore.token = response.token;
      bucketStore.objects = bucketStore.objects.concat(response.objects);

      const bucketState = this.bucketState.slice(0, indexPath - 1);

      bucketState.push(bucketStore);

      this.bucketState = bucketState;
    }
  }

}
