<voxel-form-selection class="check-all" [class.hidden]="!showCheckbox">
  <input
    type="checkbox"
    voxelCheckbox
    [checked]="check"
    (change)="checkAll($event)"
  />
  selecionar todos arquivos
</voxel-form-selection>

<div class="actions">
  <voxel-form-field label="ordernar por">
    <select voxelNativeSelect #select (change)="orderBy(select.value)">
      <option voxelNativeOption value="default" [selected]="true">
        padrão
      </option>
      <option voxelNativeOption value="name">nome</option>
    </select>
    <voxel-icon>ordenar</voxel-icon>
  </voxel-form-field>

  <button
    aria-label="listar pasta estilo lista"
    (click)="toggleListStyle()"
    [class.selected-list-style]="listStyle === 'list'"
  >
    <voxel-icon>timeline_outline</voxel-icon>
  </button>

  <button
    aria-label="listar pasta estilo ícones"
    (click)="toggleListStyle()"
    [class.selected-list-style]="listStyle === 'grid'"
  >
    <voxel-icon>menu_outline</voxel-icon>
  </button>
</div>
