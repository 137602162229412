import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  MsalService,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
} from '@azure/msal-angular';

import {
  RedirectRequest,
  InteractionStatus,
  AccountInfo,
  EventMessage,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.redirect();
      return;
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroyed$),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe({
        next: (event: EventMessage) => {
          const payload = event.payload as AuthenticationResult;
          this.updateAccount(payload.account);
          this.redirect();
        },
        error: (err) => console.error(err),
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this._destroyed$),
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe({
        next: () => this.updateAccount(),
        error: (err) => console.error(err),
      });
  }

  get activeAccount(): AccountInfo | null {
    return this.authService.instance?.getActiveAccount();
  }

  get hasAccounts(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  get isLoggedIn(): boolean {
    return this.activeAccount !== null;
  }

  redirect(): void {
    this.router.navigate(['/files']);
  }

  updateAccount(account?: AccountInfo | null): void {
    if (account) {
      this.authService.instance.setActiveAccount(account);
      return;
    }

    if (!this.activeAccount && this.hasAccounts) {
      const [account] = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(account);
    }
  }

  login(): void {
    if (!this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect();
      return;
    }

    this.authService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
