<section [attr.aria-label]="ariaLabel">
  <ng-container
    *ngIf="count === 1; then previewSingle; else previewMultiple"
  ></ng-container>

  <ng-template #previewSingle>
    <img *ngIf="previewImage" [src]="previewImage" [alt]="altText" />
    <p *ngIf="!previewImage" class="no-preview-text">
      visualização não disponível
    </p>
    <hr />
    <article>
      <p>{{ items[0].name }}</p>
      <p>{{ items[0].name }} - {{ items[0].size }}</p>
      <div>
        <p>Informações</p>
        <p>Criado: {{ items[0].lastDateModified }}</p>
        <p>Modificado: {{ items[0].lastDateModified }}</p>
      </div>
      <app-download-button></app-download-button>
    </article>
  </ng-template>

  <ng-template #previewMultiple>
    <div class="relative">
      <img
        [src]="genericImage"
        class="rotate"
        alt="imagem de vários arquivos agrupados"
      />
      <img [src]="genericImage" alt="imagem de vários arquivos agrupados" />
    </div>
    <hr style="margin-top: 54px" />
    <article>
      <p>{{ count }} itens</p>
      <p>{{ count }} documentos</p>
      <div>
        <app-download-button></app-download-button>
        <p style="font-weight: normal">
          limite máximo de <b style="font-weight: bold">50 arquivos</b>
        </p>
      </div>
    </article>
  </ng-template>
</section>
