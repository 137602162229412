import { Injectable } from "@angular/core";
import { AssetType } from "../../../shared/types/asset.type";
import { S3Object } from "../../../shared/types/s3-object.type";
import { BucketStore } from "../list.service";
import { FileMapper } from "./file-mapper";
import { FolderMapper } from "./folder-mapper";
import { Mapper } from "./mapper";

@Injectable({
  providedIn: 'root'
})
export class AssetsMapper implements Mapper<S3Object, BucketStore> {

  private cf: string = '';
  private root: string = '';

  constructor(
    private fileMapper: FileMapper,
    private folderMapper: FolderMapper,
  ) {}

  setCf(cf: string) {
    this.cf = cf;
    this.fileMapper.setCf(this.cf);
  }

  setRoot(root: string) {
    this.root = root;
    this.folderMapper.setRoot(this.root);
    this.fileMapper.setRoot(this.root);
  }

  toDomain(input: S3Object): BucketStore {
    let objectList: AssetType[] = [];
    let bucketStore: BucketStore = {
      objects: [],
      pagination: false,
      token: '',
      indexPath: -1,
      path: ''
    };
    
    try {
      const filesContents: AssetType[] = this.fileMapper.toDomain(input);
      const folderContents: AssetType[] = this.folderMapper.toDomain(input);

      objectList = objectList.concat(folderContents);
      objectList = objectList.concat(filesContents);

      bucketStore = this.makeBucketStore(objectList, input);
    } catch (err) {
      console.error(err);
    }

    return bucketStore;
  }

  private makeBucketStore(objectList: AssetType[], objects: S3Object): BucketStore {
    return {
      objects: objectList,
      pagination: objects.IsTruncated,
      token: objects.NextContinuationToken,
      indexPath: this.makeIndexByPrefix(objects.Prefix),
      path: objects.Prefix
    }
  }

  private makeIndexByPrefix(path: string): number {
    const pathIndex = path.split('/');

    pathIndex.pop();

    return pathIndex.length;
  }
}
