<div
  class="voxel-card"
  [style.pointer-events]="disabled ? 'none' : 'initial'"
  (click)="onChecked()"
>
  <voxel-form-selection>
    <input
      type="checkbox"
      voxelCheckbox
      [(ngModel)]="checked"
      [disabled]="disabled"
      [name]="asset.path"
      (change)="onChecked($event)"
    />
    <p (click)="onChecked()">{{ asset.name }}</p>
  </voxel-form-selection>
</div>
