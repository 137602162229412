import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ListStyle } from '../asset-list/asset-list.component';

export type OrderByOption = 'default' | 'name';

@Component({
  selector: 'app-asset-list-actions',
  templateUrl: './asset-list-actions.component.html',
  styleUrls: ['./asset-list-actions.component.scss'],
})
export class AssetListActionsComponent {
  listStyle: ListStyle = 'list';
  @Input() check = false;
  @Input() showCheckbox = false;
  @Output() onCheckAllAssets: EventEmitter<boolean> = new EventEmitter();
  @Output() onToggleListStyle: EventEmitter<ListStyle> = new EventEmitter();
  @Output() onOrderBy: EventEmitter<string> = new EventEmitter();

  orderBy(option: OrderByOption) {
    this.onOrderBy.emit(option);
  }

  toggleListStyle(): void {
    this.listStyle === 'list'
      ? (this.listStyle = 'grid')
      : (this.listStyle = 'list');

    this.onToggleListStyle.emit(this.listStyle);
  }

  checkAll(event: any): void {
    this.onCheckAllAssets.emit(!event.target.checked);
  }
}
