import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ObjectType } from '../../../shared/types/object-type';
import { ListObjectsInBucketService } from '../../../files/bucket/list.service';
import { AssetType } from '../../../shared/types/asset.type';
import { AssetListProps } from '../../../shared/types/assetlistprops.type';
import { SelectedAsset } from '../../../shared/types/selected-asset';
import { ChangeFolderType } from '../asset-folder/asset-folder.component';
import { OrderByOption } from '../asset-list-actions/asset-list-actions.component';
import { AssetSelectorService } from '../asset-selector.service';

export type ListStyle = 'list' | 'grid';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent implements OnInit, OnDestroy {
  @Input() filter: string = '';
  @Input() items: AssetListProps = {
    objects: [],
    pagination: false,
    token: '',
    indexPath: -1,
    path: '',
  };
  @Output() onChange: EventEmitter<SelectedAsset[]> = new EventEmitter();
  selectedAssets: SelectedAsset[] = [];
  callPagination = true;
  subscription: Subscription;
  listStyle: ListStyle = 'list';
  selectedIndex: number = -1;
  paginationOptions = { itemsPerPage: 8, currentPage: 1, totalItems: 0 };
  defaultObjects: any[];
  filterForm: FormGroup;
  objectType = ObjectType;

  constructor(
    private listObjectsInBucket: ListObjectsInBucketService,
    private assetSelectorService: AssetSelectorService
  ) {
    this.filterForm = new FormGroup({
      changePerItem: new FormControl(this.paginationOptions.itemsPerPage, [
        Validators.required,
      ]),
    });

    this.paginationOptions.totalItems = this.items.objects.length;
  }

  ngOnInit(): void {
    this.filterForm.controls['changePerItem'].valueChanges.subscribe(
      (value: number) => {
        this.paginationOptions.itemsPerPage = value;
      }
    );

    this.subscription = this.assetSelectorService.selectedAssets$.subscribe(
      (assets) => (this.selectedAssets = assets)
    );
    this.defaultObjects = this.items.objects;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get isSelectionFull() {
    return this.assetSelectorService.isSelectionFull;
  }

  get isEveryAssetChecked() {
    return this.items.objects
      .map(({ path }) => path)
      .every((path) => this.assetSelectorService.paths.includes(path));
  }

  private emitChange() {
    this.onChange.emit(this.selectedAssets);
  }

  onPageChange(page: number): void {
    this.paginationOptions.currentPage = page;
  }

  toSelectedAsset(object: AssetType) {
    return {
      path: object.path,
      name: object.name,
      checked: true,
      lastDateModified: object.lastModified,
      size: object.size,
      isPreview: object.isPreview,
      previewUrl: object.previewUrl,
    } as SelectedAsset;
  }

  checkAll() {
    const selectedAssets = this.items.objects.map(this.toSelectedAsset);
    this.assetSelectorService.checkAll(selectedAssets);
    this.emitChange();
  }

  uncheckAll() {
    const path = this.items.objects[0]?.path;
    if (path) {
      const currentFolder = this.assetSelectorService.getFolder(path);
      this.assetSelectorService.uncheckAll(currentFolder);
    } else {
      this.assetSelectorService.uncheckAll();
    }
    this.emitChange();
  }

  toggleListStyle(event: ListStyle): void {
    this.listStyle = event;
  }

  onCheck(event: boolean): void {
    event === true ? this.uncheckAll() : this.checkAll();
  }

  isChecked(asset: SelectedAsset): boolean {
    return this.assetSelectorService.isSelected(asset);
  }

  onCheckboxChange(event: SelectedAsset) {
    event.checked
      ? this.assetSelectorService.addAsset(event)
      : this.assetSelectorService.removeAsset(event);

    this.emitChange();
  }

  onChangeFolder(changeFolderType: ChangeFolderType) {
    this.selectedIndex = changeFolderType.index;
    this.listObjectsInBucket.search(changeFolderType.assetType).finally(() => {
      this.selectedIndex = -1;
    });
  }

  orderBy(option: OrderByOption) {
    switch (option) {
      case 'default':
        this.items.objects = this.defaultObjects;
        break;
      case 'name':
        this.items.objects = [...this.items.objects].sort((objA, objB) => {
          return objA.name.localeCompare(objB.name);
        });
        break;
      default:
        break;
    }
  }
}
