import { NgModule } from '@angular/core';

import { environment } from '../../environments/environment';

import {
  SegmentTypes,
  VoxelAccordionModule,
  VoxelConfigModule,
  VoxelFormFieldModule,
  VoxelInputModule,
  VoxelLoadingCircleModule,
  VoxelIconModule,
  VoxelButtonModule,
  VoxelCheckboxModule,
  VoxelFormSelectionModule,
  VoxelRadioButtonModule,
  VoxelDialogModule,
  VoxelOptionModule,
  VoxelSelectModule,
  VoxelTabsModule,
} from '@voxel/components';

const MODULES = [
  VoxelIconModule,
  VoxelButtonModule,
  VoxelCheckboxModule,
  VoxelFormSelectionModule,
  VoxelInputModule,
  VoxelAccordionModule,
  VoxelLoadingCircleModule,
  VoxelFormFieldModule,
  VoxelFormSelectionModule,
  VoxelRadioButtonModule,
  VoxelDialogModule,
  VoxelOptionModule,
  VoxelSelectModule,
  VoxelTabsModule
];

@NgModule({
  imports: [
    VoxelConfigModule.forRoot({
      production: environment.production,
      segment: SegmentTypes.Varejo,
    }),
    MODULES,
  ],
  exports: [MODULES],
})
export class VoxelModule {}
