export const environment = {
  production: false,
  apiUrl: 'https://martechapps-hom.cloud.itau.com.br',
  cfPreview: 'https://martech-asset-viewer-hom.cloud.itau.com.br/',
  originCfApigw: 'RwwY5DamAdDvJnB3i3eCdH/RKx0huPiD8Ttu8awQuTazoTl/bVcxUtzm+YD8XbZSq3Bzsja8AtyHUm88v57/Tw==',
  correlationID: '123',
  clientID: 'b642954e-bc89-41e7-aac5-a72b16502503',
  authEndpoint: 'https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81/oauth2/v2.0/authorize?client_id=b642954e-bc89-41e7-aac5-a72b16502503&response_type=code&redirect_uri=https://martech-asset-viewer-hom.cloud.itau.com.br&scope=openid&state=47947535-8509-40be-a69c-5ff4335b862d&nonce=0901a79a-1b76-4204-8159-7fcab2ccea2b&code_challenge=CODE_CHALLENGE&code_challenge_method=S256',
  metaDataURL: 'https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81/v2.0/.well-known/openid-configuration',
  tenant: '591669a0-183f-49a5-98f4-9aa0d0b63d81',
  redirectUri: 'https://martech-asset-viewer-hom.cloud.itau.com.br/',
  bucket: '',
  accessKeyId: '',
  secretAccessKey: '',
  sessionToken: '',
  csApiUrl: 'https://cdn.contentstack.io/v3/content_types/squadcmshead_maite_buckets_parametrizaveis/entries/blt38ca4efd7a8ce270',
  csApiKey: 'blt71edc64f213dc5cd',
  csAccessToken: 'cseb15be2d44e3ae3d5836b813',
};
