<button
  #downloadButton
  voxelButton
  [aria-label]="ariaLabel"
  [disabled]="!hasAssets"
  (click)="toggleDialog(true)"
>
  <voxel-icon>download_outline</voxel-icon>
  download
</button>

<voxel-dialog
  class="dialog"
  isFooterInline
  [dialogIsOpen]="showDialog"
  [dialogOrigin]="downloadButton"
  dialogAriaLabelledby="dialog-title"
  (dialogClose)="toggleDialog(false)"
>
  <div class="dialog-header">
    <h2 id="dialog-title">preparando o download...</h2>
    <button
      (click)="toggleDialog(false)"
      title="fechar janela"
      aria-label="fechar janela"
    >
      <voxel-icon>fechar</voxel-icon>
    </button>
  </div>

  <p style="margin-top: 1em">os arquivos serão baixados separadamente.</p>

  <div class="dialog-footer">
    <button
      voxelButton
      aria-label="baixar"
      [buttonLoad]="loading"
      (click)="downloadAssets()"
    >
      ok
    </button>
    <button voxelButton buttonStyle="secondary" (click)="cancel()">
      cancelar
    </button>
  </div>
</voxel-dialog>
