import { Injectable } from '@angular/core';
import { ObjectType } from '../../../shared/types/object-type';
import { AssetType } from '../../../shared/types/asset.type';
import { IContents } from '../../../shared/types/contents.type';
import { S3Object } from '../../../shared/types/s3-object.type';
import { Mapper } from './mapper';

@Injectable({
  providedIn: 'root',
})
export class FileMapper implements Mapper<S3Object, AssetType[]> {
  private cf: string;
  private root: string;

  toDomain(input: S3Object): AssetType[] {
    return input.Contents.map((object: any) => {
      return {
        ...object,
        objectKey: object.Key || object.objectKey,
      } as IContents;
    })
      .filter(({ StorageClass, objectKey }: IContents) => {
        return StorageClass === 'STANDARD' && this.isFile(objectKey);
      })
      .map((object: IContents) => {
        return {
          name: this.nameWithoutPrefix(object.objectKey, input.Prefix),
          type: ObjectType.File,
          size: this.formatBytes(object.Size),
          path: object.objectKey,
          isPreview: this.isPreview(object, input),
          previewUrl: this.makePreviewUrl(object),
          lastModified: this.convertStringToDate(object.LastModified),
          index: this.makeIndexByPrefix(object.objectKey),
        };
      });
  }
  makePreviewUrl(object: IContents): string {
    let previewUrl = object.objectKey.split('/');
    previewUrl.shift();
    return `${this.cf}${previewUrl.join('/')}`;
  }

  setCf(cf: string) {
    this.cf = cf;
  }

  setRoot(root: string) {
    this.root = root;
  }

  private makeIndexByPrefix(path: string): number {
    const pathIndex = path.replace(this.root, '').split('/');
    pathIndex.pop();
    return pathIndex.length;
  }

  private nameWithoutPrefix(name: string, prefix: string): string {
    return name.replace(prefix, '');
  }

  private isFile(asset: string): Boolean {
    return asset.charAt(asset.length - 1) !== '/';
  }

  private convertStringToDate(date: string): string {
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  }

  isPreview(object: IContents, input: S3Object): boolean {
    const filename: string = this.nameWithoutPrefix(
      object.objectKey,
      input.Prefix
    );
    const image_extensions = [
      'apng',
      'avif',
      'gif',
      'jpeg',
      'jpg',
      'png',
      'svg',
      'webp',
    ];
    const fileanemSplit = filename.split('.');
    const extension = fileanemSplit[fileanemSplit.length - 1];

    return image_extensions.includes(extension);
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
}
