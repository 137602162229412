<app-asset-list-actions
  *ngIf="items.objects.length"
  class="list-style"
  [showCheckbox]="items.objects.length > 0"
  [check]="isEveryAssetChecked || isSelectionFull"
  (onOrderBy)="orderBy($event)"
  (onCheckAllAssets)="onCheck($event)"
  (onToggleListStyle)="toggleListStyle($event)"
></app-asset-list-actions>
<div
  *ngIf="items.objects.length; else emptyList"
  class="asset-list"
  [class.voxel-row]="listStyle === 'grid'"
>
  <div
    *ngFor="
      let object of items.objects
        | paginate: paginationOptions
        | filterAssets: filter;
      let indexFolder = index
    "
    [class.voxel-col-6]="listStyle === 'grid'"
  >
    <app-asset-folder
      *ngIf="object.type === objectType.Folder"
      [folder]="object"
      (onChangeFolder)="onChangeFolder($event)"
      [index]="indexFolder"
      [selectedIndex]="selectedIndex"
    ></app-asset-folder>
    <app-asset-checkbox
      *ngIf="object.type === objectType.File"
      [asset]="object"
      [disabled]="isSelectionFull && !isChecked(object)"
      [checked]="isChecked(object)"
      (onChange)="onCheckboxChange($event)"
    ></app-asset-checkbox>
  </div>
</div>
<div class="asset-footer">
  <div class="container-per-item">
    <span>itens por página:</span>
    <form [formGroup]="filterForm">
      <select formControlName="changePerItem">
        <option>4</option>
        <option>8</option>
        <option>12</option>
        <option>16</option>
      </select>
    </form>
  </div>
  <div class="container-pagination-controls">
    <pagination-controls
      previousLabel=""
      nextLabel=""
      *ngIf="items.objects.length"
      (pageChange)="onPageChange($event)"
    ></pagination-controls>
  </div>
</div>
<ng-template #emptyList>
  <p class="empty-list">Nenhuma pasta ou arquivo para exibir</p></ng-template
>
